<template>
  <div class="text-left">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="lighten-2" dark v-bind="attrs" v-on="on">
          Add Deck
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Deck
        </v-card-title>

        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="text"
                  v-model="programName"
                  label="Program Name"
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createProgram()"> Add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
  props: ["currentCoachId"],
  // mounted()
  methods: {
    ...mapActions({
      getCoachPrograms: "getCoachPrograms",
      getCoachData: "getCoachData",
    }),
    async createProgram() {
      if (this.$refs.form.validate()) {
        const done = await this.$apollo.mutate({
          mutation: gql`
            mutation createProgram($data: ProgramInput!) {
              createProgram(data: $data) {
                id
              }
            }
          `,
          variables: {
            data: {
              name: this.programName,
              coachId: parseInt(this.currentCoachId),
              frequency: this.frequency || "daily",
              status:'draft',
              skipWeekends: this.skipWeekends
            },
          },
        });

        if (done) {
          this.dialog = false;
          this.programName = null;
          this.frequency = null;
          this.frequencyDetail = null;
          this.frequencyTime = null;

          this.getCoachPrograms({ coachId: this.currentCoachId });
          this.getCoachData();

          return true;
        }
      }
    },
  },
  computed: {
    frequencyDetailValues() {
      if (this.frequency === "monthly")
        return [...Array(28).keys()].map((num) => num + 1);
      if (this.frequency === "weekly") {
        return [
          { value: "0", text: "Monday" },
          { value: "1", text: "Tuesday" },
          { value: "2", text: "Wednesday" },
          { value: "3", text: "Thursday" },
          { value: "4", text: "Friday" },
          { value: "5", text: "Saturday" },
          { value: "6", text: "Sunday" },
        ];
      }
      return 0;
    },
  },
  data() {
    return {
      dialog: false,
      time: null,
      menu2: false,
      programName: "",
      frequency: null,
      frequencyValues: ["daily", "weekly"],
      frequencyDetail: 0,
      frequencyTimeValues: 0,
      skipWeekends: false,
      isShowingPicker: false,
      timezone: "",
      timezones: [
        -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6,
        7, 8, 9, 10, 12,
      ],
    };
  },
};
</script>
